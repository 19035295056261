import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"

const NotFoundPage = () => {

  return (
    <Layout>
      <div className="md:hidden">
        <StaticImage
          placeholder="dominantColor"
          src="../images/404-mobile.png"
          alt="Not Found Nullarbor" />
      </div>
      <div className="hidden w-full lg:block">
        <StaticImage
          className="w-full"
          src="../images/404.png"
          placeholder="dominantColor"
          objectFit="cover"
          alt="Not Found Nullarbor" />
      </div>
      <section className="flex flex-col items-center max-w-screen-lg py-10 mx-4 text-center lg:mx-auto">
        <h1 className="mb-10 text-5xl font-extrabold lg:text-6xl text-anomaly-red">Not all those who wander are lost.</h1>
        <p className="text-3xl font-semibold text-gray-500">We are sorry you can't find the page you were looking for.</p>
      </section>
    </Layout>
  )
}

export default NotFoundPage
